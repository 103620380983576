import React, { Component } from 'react';
import { render } from 'react-dom';
import './style.css';

function _(data) {
  let tmp = "0" +data
  return tmp.substr(tmp.length-2, 2)
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      sloveso: 'mám'
    };
    this.days = [
      "Pondělí",
      "Úterý",
      "Středa",
      "Čtvrtek",
      "Pátek",
      "Sobota",
      "Neděle"
    ]
  }

  onChange(event) {
    this.setState({sloveso: !event.target.checked ? "mám" : "nemám"})
  }

  render() {
    const times = []
    for (let i=0; i<7; ++i) {
      let hour = parseInt(Math.random()*20 + 2)
      let minute = parseInt(Math.random()*60)
      times.push({hour, minute})
    }
    return (
      <div>
        <h1>Kdy <strong>{this.state.sloveso}</strong> jít nakupovat tento týden</h1>
        <ul>
          <li>Pondělí {_(times[0].hour)}:{_(times[0].minute)} - {_(times[0].hour  + 2)}:{_(times[0].minute)}</li>
          <li>Úterý {_(times[1].hour)}:{_(times[1].minute)} - {_(times[1].hour+ 2) }:{_(times[1].minute)}</li>
          <li>Středa {_(times[2].hour)}:{_(times[2].minute)} - {_(times[2].hour+ 2) }:{_(times[2].minute)}</li>
          <li>Čtvrtek {_(times[3].hour)}:{_(times[3].minute)} - {_(times[3].hour+ 2) }:{_(times[3].minute)}</li>
          <li>Pátek {_(times[4].hour)}:{_(times[4].minute)} - {_(times[4].hour + 2) }:{_(times[4].minute)}</li>
          <li>Sobota {_(times[5].hour)}:{_(times[5].minute)} - {_(times[5].hour + 2) }:{_(times[5].minute)}</li>
          <li>Neděle {_(times[6].hour)}:{_(times[6].minute)} - {_(times[6].hour  + 2)}:{_(times[6].minute)}</li>
        </ul>
        <div>
          <input type="Checkbox" onChange={(event) => {this.onChange(event)}}/> <label htmlFor="age">Je mi více než 65 let</label>
        </div>
      </div>
    );
  }
}

render(<App />, document.getElementById('root'));
